body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

th[class^="MuiTableCell-root"],
td[class^="MuiTableCell-root"] {
  text-align:right;
}

label[class^="MuiFormLabel-root"] {
  left: auto;
  right:0;
  transform-origin: top right;
}

label[class^="MuiInputLabel-shrink"] {
  transform-origin:top right;
}

p[class^="MuiFormHelperText-root"] {
  text-align: right;
}

label[class^="MuiFormControlLabel-root"]{
  margin-right:-14px;
  margin-left:16px;
}

span[class^="MuiSwitch-checked-"]{
  transform: translateX(-14px);
}
th, td {
  text-align:right !important; //TODO: remove this
}